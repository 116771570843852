import { type Ref, ref, unref } from 'vue'
import { Breakpoints } from '../../config'

type BreakpointKey = keyof typeof Breakpoints
type BreakpointComparator = Ref<boolean>
type BreakpointsComputed = {
  [key in BreakpointKey]: BreakpointComparator[]
}

const isReady = false

/**
 * возвращает объект вида:
 * screenWidth = documentElement.scrollWidth
 * {
 *   breakpoint: [
 *     screenWidth >= breakpoint,
 *     screenWidth <= breakpoint,
 *     screenWidth === breakpoint,
 *   ]
 * }
 */
export const useBreakpoint = (): BreakpointsComputed => {
  const getScreenSize = () => window.innerWidth

  const breakpointComparatorFactory = (key: BreakpointKey): BreakpointComparator[] => [
    ref(getScreenSize() >= Breakpoints[key]),
    ref(getScreenSize() < Breakpoints[key]),
    ref(getScreenSize() === Breakpoints[key])
  ]
  const breakpointsComputed: BreakpointsComputed = {
    xxs: breakpointComparatorFactory('xxs'),
    xs: breakpointComparatorFactory('xs'),
    sm: breakpointComparatorFactory('sm'),
    md: breakpointComparatorFactory('md'),
    lg: breakpointComparatorFactory('lg'),
    xl: breakpointComparatorFactory('xl'),
    '2xl': breakpointComparatorFactory('2xl')
  }

  const updateValues = () => {
    Object.keys(Breakpoints).forEach((key) => {
      const [newUp, newDown, newOnly] = breakpointComparatorFactory(key as BreakpointKey)
      const [oldUp, oldDown, oldOnly] = breakpointsComputed[
        key as BreakpointKey
      ] as BreakpointComparator[]

      oldUp.value = unref(newUp)
      oldDown.value = unref(newDown)
      oldOnly.value = unref(newOnly)
    })
  }

  !isReady && window.addEventListener('resize', updateValues)

  return {
    ...breakpointsComputed
  }
}
